import React from "react"

import Layout from "~/components/common/Layout"
import {
  getBackLink,
  getHeaderTitles,
  getNextLink,
  getNextTitle,
} from "~/services/pages"
import withProgress from "~/services/withProgress"
import { graphql, useStaticQuery } from "gatsby"
import HeroVideo from "~/components/page/HeroVideo"
import { Heading, Text } from "~/components/common/Typography"
import { Box, Flex } from "reflexbox"
import Img from "gatsby-image"
import { InfoboxDetailed } from "~/components/page/Infobox"
import { css } from "@emotion/core"
import GraphicWithText from "~/components/page/GraphicWithText"
import { mq } from "~/components/common/theme"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"

const Page = ({ myPage = 3, mySubpage = 2 }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(name: { eq: "4.3HERO" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      brainBottom: file(name: { eq: "4.3k-ADIDAS-brain" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      brain: file(name: { eq: "4.3a-ADIDAS-brain" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      stress: file(name: { eq: "4.3j-ADIDAS-howstressworks" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      fff1: file(name: { eq: "4.3-ADIDAS-illustration1" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      fff2: file(name: { eq: "4.3-ADIDAS-illustration2" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      fff3: file(name: { eq: "4.3-ADIDAS-illustration3" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic1: file(name: { eq: "4.3b-ADIDAS-bloodflow" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic2: file(name: { eq: "4.3c-ADIDAS-heartrate" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic3: file(name: { eq: "4.3d-ADIDAS-digestion" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic4: file(name: { eq: "4.3e-ADIDAS-adrenaline" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic5: file(name: { eq: "4.3f-ADIDAS-sweat" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic6: file(name: { eq: "4.3g-ADIDAS-irritated" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic7: file(name: { eq: "4.3h-ADIDAS-restless" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      graphic8: file(name: { eq: "4.3i-ADIDAS-mindraces" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const {
    hero,
    brainBottom,
    brain,
    stress,
    fff1,
    fff2,
    fff3,
    graphic1,
    graphic2,
    graphic3,
    graphic4,
    graphic5,
    graphic6,
    graphic7,
    graphic8,
  } = data

  const graphicsData = [
    {
      image: graphic1,
      text: `Blood flows to their extremities`,
    },
    {
      image: graphic2,
      text: `Their heart rates go up`,
    },
    {
      image: graphic3,
      text: `Digestion stops, and their stomachs get upset`,
    },
    {
      image: graphic4,
      text: `Adrenaline floods their bodies`,
    },
    {
      image: graphic5,
      text: `They sweat`,
    },
    {
      image: graphic6,
      text: `They feel irritated`,
    },
    {
      image: graphic7,
      text: `They can’t sleep or get restless`,
    },
    {
      image: graphic8,
      text: `Their minds race`,
    },
  ]

  const graphicsData2 = [
    {
      image: fff2,
      title: "Fight:",
      text: `A player lashes out in defense`,
    },
    {
      image: fff1,
      title: "Flight:",
      text: `A player tries to escape, withdraw, or shut down`,
    },
    {
      image: fff3,
      title: "Freeze:",
      text: `A player tries to go unnoticed`,
    },
  ]

  const graphicMq = mq({
    width: ["70%", "80%", "100%"],
  })

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="Now that you understand how stress is processed in the brain, let’s apply it to coaching."
        image={hero}
        bg={"white"}
      />

      <InfoboxDetailed
        headerText={
          "There are all kinds of stressors in life that your players might face, on or off the field."
        }
        headerMaxWidth={"desktop"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
        pb={0}
      >
        <Box maxWidth={"500px"} mb={[5, 4]} mt={6} mx={"auto"}>
          <Img
            fluid={brain.childImageSharp.fluid}
            margin={"0 auto"}
            loading={"eager"}
          ></Img>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={
          "When this happens, they feel stressed, and something happens to their bodies."
        }
        headerMaxWidth={"desktop"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
        pt={6}
      >
        <Flex
          maxWidth={"994px"}
          mx={"auto"}
          mt={["16px", "16px", "24px"]}
          justifyContent={"center"}
          alignItems={"start"}
          css={css`
            flex-wrap: wrap;
          `}
        >
          {graphicsData.map((item, index) => (
            <GraphicWithText
              mq={graphicMq}
              image={item.image}
              width={["100%", "50%", "25%"]}
              key={index}
              px={6}
            >
              <Text uppercase fontFamily={"AdineuePROBlackWeb"}>
                {item.text}
              </Text>
            </GraphicWithText>
          ))}
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"Fight, flight, or freeze?"}
        headerMaxWidth={"desktop"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
        bg={"seafoam"}
        pt={6}
      >
        <Box bg={"white-panel"} mt="5" px={[5, 5, 6]} py={[6, 6]}>
          <Flex flexDirection={"column"}>
            <Flex
              mx={"auto"}
              justifyContent={"center"}
              alignItems={"start"}
              width={"100%"}
              css={css`
                flex-wrap: wrap;
              `}
            >
              {graphicsData2.map((item, index) => (
                <GraphicWithText
                  mq={graphicMq}
                  image={item.image}
                  width={["100%", "33%"]}
                  key={index}
                  px={4}
                >
                  <Box
                    fontSize={["16px", "16px"]}
                    lineHeight={["18px", "21px"]}
                  >
                    <Heading
                      uppercase
                      as="h3"
                      fontFamily={"AdineuePROBoldWeb"}
                      fontSize={["16px", "16px"]}
                      lineHeight={["18px", "21px"]}
                    >
                      {item.title}
                    </Heading>
                    <Text mt={0}>{item.text}</Text>
                  </Box>
                </GraphicWithText>
              ))}
            </Flex>

            <Box maxWidth={"mediumContent"} mx={"auto"} mt={0} width={"100%"}>
              <Text mt={0}>
                When you get stressed, you’re not at your best.
              </Text>
              <Text>
                But girls and adults experience stress differently. Some girls
                who experience a lot of stress in their lives actually
                experience changes in their brains.
              </Text>
            </Box>
          </Flex>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={
          "Sports can change everything for girls when it comes to stress. We’ve known this for a long time."
        }
        headerMaxWidth={"desktop"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
        pt={6}
      >
        <Flex
          flexDirection={"column"}
          maxWidth={"mediumContent"}
          mx={"auto"}
          width={"100%"}
          justifyContent={"center"}
        >
          <Text>
            A 1997 study for the President's Council on Physical Fitness and
            Sports found that participating in sports enhanced both the physical
            and mental health of adolescent girls, improving self-esteem,
            self-confidence, competence, mental health, and body image.
            <Source>30</Source>
          </Text>
          <Text>
            When Sumru Erkut, associate director of the Center for Research on
            Women at Wellesley College, and three colleagues embarked on their
            1996 investigation of girls and self-esteem, they expected to find
            that girls gained their sense of worth from taking care of others.
            But in their nationwide survey of 362 girls between the ages of 6
            and 18, nearly half said the activity that made them feel good about
            themselves was athletics.<Source>31</Source>
          </Text>
          <Box width={"100%"} mb={[4]} mt={4} mx={"auto"}>
            <Img
              fluid={stress.childImageSharp.fluid}
              margin={"0 auto"}
              loading={"eager"}
            ></Img>
          </Box>
          <Text>
            Stress happens. It happens to girls more often than we imagine.{" "}
            <br />
            The situation is experienced subjectively. Each girl’s amygdala
            determines how dangerous something is. <br />
            Stress disrupts her equilibrium, in a good way or a bad way. Her
            experiences and skills determine how she reacts.
          </Text>

          <Text>
            For example, someone who experiences a serious car accident may feel
            stressed driving in bad weather, while someone who has never been in
            a car accident may feel fine. <br /> A stress response that was
            useful in a moment of stress can become a default stress response,
            even when that response isn’t particularly helpful in all
            situations. A girl’s brain rewires to put the amygdala in charge,
            overriding the prefrontal cortex.
            <br />
          </Text>
          <Text>
            Small triggers may elicit the same stress response as big triggers.
            A girl may experience a level 10 stressor (such as getting bullied)
            and reach a level 10 response (such as fighting back). But when this
            continuously happens, the response gets wired in, and even when she
            experiences a level 2 stressor (such as getting nudged on the
            track), she’ll still default to that level 10 response. The amygdala
            can no longer differentiate between small and big stressors.
          </Text>
          <Text>
            This is the behavior girls show when they’re under stress and what
            you will see as a coach.
          </Text>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={"What happens when brains put the amygdala in charge?"}
        maxWidth={"100%"}
        bg={"seafoam"}
        headerBackground={"transparent"}
        contentMaxWidth={"desktop"}
      >
        <Box bg={"white-panel"} mt="5" px={[5, 5, 6]} py={[6, 6]}>
          <Flex flexDirection={["column"]} mt={4}>
            <Box width={"100%"} maxWidth="800px" mx="auto" mb={[5, 0]} mt={4}>
              <Img
                fluid={brainBottom.childImageSharp.fluid}
                margin={"0 auto"}
                loading={"eager"}
              ></Img>
            </Box>
            <Flex
              width={["100%"]}
              textAlign={"center"}
              maxWidth="800px"
              mx="auto"
            >
              <Flex flexGrow={1} flexBasis={0}>
                <Text lineHeight={1.5}>
                  On the left is a brain that hasn't experienced toxic or
                  chronic stress. Notice how there is a lot of activity in the
                  front where the prefrontal cortex is located.
                </Text>
              </Flex>
              <Box width={"15%"} />
              <Flex flexGrow={1} flexBasis={0}>
                <Text lineHeight={1.5}>
                  On the right is a brain that is chronically stressed. Notice
                  how the activity is concentrated in the back where the
                  amygdala is located.
                </Text>
              </Flex>
            </Flex>
            <Box
              width={["100%"]}
              textAlign={"center"}
              maxWidth="800px"
              mx="auto"
              mt={5}
            >
              <Text>
                This is what the physiological rewiring looks like. When a
                player is in this kind of state, she is not able to think in the
                same way as a normal person reacting to stress.
              </Text>
            </Box>
          </Flex>
        </Box>
      </InfoboxDetailed>

      <Copyright bg={"seafoam"} />
    </Layout>
  )
}

export default withProgress(Page, 3, 2)
